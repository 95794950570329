import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom'
import PagePlaceholder from 'src/components/DetailsPage/PagePlaceholder'
import PhotoWall from 'src/components/PhotoWall/PhotoWall'
import { imageToPWImage, usePanel } from 'src/hooks/api'

const Panel = () => {
  const { slug } = useParams()
  const images = usePanel(slug)?.images || []
  const entries = images.map(imageToPWImage)
  return (
    <PhotoWall
      className="photos album"
      entries={entries}
    />
  )
}

const PanelWrapper = () =>
  <Suspense fallback={<PagePlaceholder className="album" />}>
    <Panel />
  </Suspense>

export default PanelWrapper
