import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PhotoWall from 'src/components/PhotoWall/PhotoWall'
import { panelToPWImage, usePanels } from 'src/hooks/api'
import Loading from 'src/components/Loading/Loading'
import PanelEntry from './PanelEntry'

const Panels = () => {
  const panels = usePanels()
  const entries = panels.map(panelToPWImage)

  return (
    <>
      <PhotoWall
        className="photos tags"
        entries={entries}
        ImageComponent={PanelEntry}
      />
    </>
  )
}

const PanelsWrapper = () => {
  const isLoggedIn = useSelector((s: any) => !!s.user)
  if (!isLoggedIn) return <Redirect to="/" />

  return (
    <Suspense fallback={<Loading className="photos panels" />}>
      <Panels />
    </Suspense>
  )
}

export default PanelsWrapper
