import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ModalWrapper, Modal } from '@gluedigital/modal'
import Home from './Home/Home'
import Album from './Album/Album'
import Panels from './Panels/Panels'
import Panel from './Panel/Panel'
import Tags from './Tags/Tags'
import Tag from './Tag/Tag'
import BulkTag from './Tag/Bulk'
import Canvas from './Canvas/Canvas'
import Meta from 'src/components/Meta'
import Header from 'src/components/Header/Header'
import { Store } from 'src/types'

import 'src/components/Modals/Login/Login'

const App = () =>
  <Suspense fallback={false}>
    <ModalWrapper>
      <Meta />
      <Header />
      <Modal />
      <Routes />
    </ModalWrapper>
  </Suspense>

const Routes = () => {
  const username = useSelector((s: Store) => s.user?.username)
  return (
    <Switch key={username}>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path={['/album/new', '/album/:slug/:title?', '/album/:slug/:title/edit']} exact>
        <Album />
      </Route>
      <Route path="/panels" exact>
        <Panels />
      </Route>
      <Route path="/panel/:slug/:title?" exact>
        <Panel />
      </Route>
      <Route path="/tags" exact>
        <Tags />
      </Route>
      <Route path="/tag/:tag" exact>
        <Tag />
      </Route>
      <Route path="/tag/:tag/edit" exact>
        <BulkTag />
      </Route>
      <Route path="/canvas" exact>
        <Canvas />
      </Route>
    </Switch>
  )
}

export default App
