import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Link } from 'react-router-dom'
import { ModalLink } from '@gluedigital/modal'
import './Header.sass'
import Search from '../Search/Search'
import { Store } from 'src/types'

const Header = () => {
  const me = useSelector((s: Store) => s.user)
  const dispatch = useDispatch()
  const handleLogout = async () => {
    const url = (process.env.APP_URL || 'http://localhost:3000') + '/api/logout'
    await fetch(url, { method: 'POST' })
    dispatch({ type: 'user/logout' })
  }

  return (
    <header id="top-bar">
      <div className="left">
        <Link to="/">
          <h1><FormattedMessage id="site.title" /></h1>
        </Link>
      </div>
      {me &&
        <div className="center">
          <Search />
        </div>
      }
      <div className="right">
        <Route path="/" exact>
          <AddAlbumButton />
        </Route>
        {me &&
          <span className="avatar" title={me.username}>{me.username[0]}</span>
        }
        <span className="menu" tabIndex={0}>
          <span className="icon icon-menu" />
          <div className="menu-popup" onClick={() => (document.activeElement as any).blur()}>
            <Link to="/">Home</Link>
            {me &&
              <Link to="/tags">Tags</Link>
            }
            {me &&
              <Link to="/panels">Panels</Link>
            }
            <Link to="/canvas">Canvas</Link>
            {!me &&
              <ModalLink modal="login"><span>Login</span></ModalLink>
            }
            {me &&
              <a onClick={handleLogout}>Logout</a>
            }
          </div>
        </span>
      </div>
    </header>
  )
}

const AddAlbumButton = () => {
  const loggedIn = useSelector((s: Store): boolean => !!s.user)
  if (!loggedIn) return null
  return (
    <Link className="icon icon-add" to="/album/new" />
  )
}

export default Header
