import React from 'react'
import { useHistory } from 'react-router-dom'
import { ImageProps } from 'src/components/PhotoWall/Image'
import { Image } from 'src/components/PhotoWall/types'
import { Tag } from 'src/types'
import './PanelEntry.sass'



const PanelEntry: React.FC<ImageProps<Tag & Image>> = ({ style, entry }) => {
  const history = useHistory()
  const fullStyle = {
    ...style,
    backgroundImage: `url("${entry.url}")`
  }
  const handleClick = e => {
    if (entry.href) {
      e.preventDefault()
      history.push(entry.href)
    }
  }

  return (
    <a className="image panel-entry" style={fullStyle} onClick={handleClick} href={entry.href}>
      <div className="title">{entry.title}</div>
    </a>
  )
}

export default PanelEntry
